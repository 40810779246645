'use strict'

const polyfillIfNotInSSR = () => {
  if (!self.isPseudoWorker) {
    require('@babel/polyfill')
    require('whatwg-fetch')
  } else {
    require('regenerator-runtime/runtime')
  }
}

polyfillIfNotInSSR()
